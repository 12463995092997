import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter)
const routes = [
    {
        path: '/',
        redirect: '/home'
    }
]
export const routes1 = [
    {
        path: '/index',
        component: () => import('@/pages/pc'),
        meta: {
            title: '企规宝-首页'
        },
        children: [
            {
                path: '/home',
                component: () => import('@/pages/pc/home'),
                meta: {
                    title: '企规宝-首页'
                },
            },
            {
                path: '/about',
                component: () => import('@/pages/pc/about'),
                meta: {
                    title: '企规宝-公司介绍'
                },
            },
            {
                path: '/product-service',
                component: () => import('@/pages/pc/productService'),
                meta: {
                    title: '企规宝-创扶财务官'
                },
            },
            {
                path: '/public-welfare',
                component: () => import('@/pages/pc/publicWelfare'),
                meta: {
                    title: '企规宝-创扶公益'
                },
            },
            {
                path: '/information',
                component: () => import('@/pages/pc/information'),
                meta: {
                    title: '企规宝-资讯'
                },
            },
            {
                path: '/contact',
                component: () => import('@/pages/pc/contact'),
                meta: {
                    title: '企规宝-联系我们'
                },
            },
        ]
    },

]
export const routes2 = [
    {
        path: '/index',
        component: () => import('@/pages/mobile'),
        meta: {
            title: '企规宝-首页'
        },
        children: [
            {
                path: '/home',
                component: () => import('@/pages/mobile/home'),
                meta: {
                    title: '企规宝-首页'
                },
            },
            {
                path: '/about',
                component: () => import('@/pages/mobile/about'),
                meta: {
                    title: '企规宝-公司介绍'
                },
            },
            {
                path: '/product-service',
                component: () => import('@/pages/mobile/productService'),
                meta: {
                    title: '企规宝-创扶财务官'
                },
            },
            {
                path: '/public-welfare',
                component: () => import('@/pages/mobile/publicWelfare'),
                meta: {
                    title: '企规宝-创扶公益'
                },
            },
            {
                path: '/information',
                component: () => import('@/pages/mobile/information'),
                meta: {
                    title: '企规宝-资讯'
                },
            },
            {
                path: '/contact',
                component: () => import('@/pages/mobile/contact'),
                meta: {
                    title: '企规宝-联系我们'
                },
            },
        ]
    },

]



const router = new VueRouter({
    routes,
    base: process.env.BASE_URL,
    mode: 'history',
    linkActiveClass: 'active',
    scrollBehavior: () => {
        history.pushState(null, null, document.URL);
    }
})

//新版本记录路由导航控制台会报错
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}


export default router